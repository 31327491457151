// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Fetch from "../../../../node_modules/bs-fetch/lib/es6_global/src/Fetch.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Js_json from "../../../../node_modules/rescript/lib/es6/js_json.js";
import * as $$Promise from "../../../../node_modules/@ryyppy/rescript-promise/lib/es6_global/src/Promise.bs.js";
import * as Rollbar from "../../../../node_modules/rescript-rollbar/lib/es6_global/src/Rollbar.bs.js";
import * as Caml_obj from "../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Env$Showside from "./Env.bs.js";
import * as Caml_exceptions from "../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as JsUtils$Showside from "./JsUtils.bs.js";
import IsNetworkError from "is-network-error";
import * as URLUtils$Showside from "./URLUtils.bs.js";
import * as Caml_js_exceptions from "../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
import * as PromiseUtils$Showside from "./PromiseUtils.bs.js";
import * as RollbarInstance$Showside from "./RollbarInstance.bs.js";
var ServerError = /* @__PURE__ */Caml_exceptions.create("FetchUtils-Showside.ServerError");
var DecodeError = /* @__PURE__ */Caml_exceptions.create("FetchUtils-Showside.DecodeError");
var NetworkError = /* @__PURE__ */Caml_exceptions.create("FetchUtils-Showside.NetworkError");
function fetchWithInit(url, requestInit) {
  var $$fetch$1 = window.makerFetch;
  if ($$fetch$1 !== undefined) {
    return $$fetch$1(url, requestInit);
  } else {
    return fetch(url, requestInit);
  }
}
function entries(headers) {
  return Object.fromEntries(headers.entries());
}
var $$Headers = {
  entries: entries
};
function toString(method_) {
  if (typeof method_ !== "number") {
    return method_._0.toUpperCase();
  }
  switch (method_) {
    case /* Get */0:
      return "GET";
    case /* Head */1:
      return "HEAD";
    case /* Post */2:
      return "POST";
    case /* Put */3:
      return "PUT";
    case /* Delete */4:
      return "DELETE";
    case /* Connect */5:
      return "CONNECT";
    case /* Options */6:
      return "OPTIONS";
    case /* Trace */7:
      return "TRACE";
    case /* Patch */8:
      return "PATCH";
  }
}
var Method = {
  toString: toString
};
async function toObject(req) {
  var req$1;
  try {
    req$1 = req.clone();
  } catch (exn) {
    req$1 = req;
  }
  var text = await $$Promise.$$catch(Fetch.$$Request.text(req$1), function (param) {
    return Promise.resolve("");
  });
  var headers = req$1.headers;
  return {
    url: req$1.url,
    headers: Object.fromEntries(headers.entries()),
    method: toString(Fetch.$$Request.method_(req$1)),
    text: text
  };
}
var $$Request = {
  toObject: toObject
};
async function toObject$1(res) {
  var res$1;
  try {
    res$1 = res.clone();
  } catch (exn) {
    res$1 = res;
  }
  var text = await $$Promise.$$catch(Fetch.$$Response.text(res$1), function (param) {
    return Promise.resolve("");
  });
  var headers = res$1.headers;
  return {
    url: res$1.url,
    headers: Object.fromEntries(headers.entries()),
    status: res$1.status,
    statusText: res$1.statusText,
    text: text
  };
}
var $$Response = {
  toObject: toObject$1
};
var IsNetworkError$1 = {};
var undefinedErrorMessage = "Unknown error occurred";
function isCriticalError(statusCode, requestUrl) {
  if (requestUrl.startsWith(Env$Showside.apiHostnameProduction)) {
    return Caml_obj.notequal(statusCode, 403);
  } else {
    return false;
  }
}
function makeDisplayUrl(urlStr) {
  var url = URLUtils$Showside.maybeMakeUrl(URLUtils$Showside.resolveRelativePath(urlStr));
  if (url === undefined) {
    return urlStr;
  }
  var url$1 = Caml_option.valFromOption(url);
  var pathname = url$1.pathname;
  var host = url$1.host;
  return host + pathname;
}
function makeError(req, res, message, param) {
  var statusCode = res.status;
  var url = res.url;
  var displayUrl = makeDisplayUrl(url);
  return {
    exn: {
      RE_EXN_ID: ServerError,
      _1: "Error " + statusCode.toString() + " in API " + displayUrl + ": " + message + ""
    },
    request: req,
    response: res
  };
}
function truncateTo100Characters(str) {
  if (str.length > 100) {
    return str.slice(0, 100) + "...";
  } else {
    return str;
  }
}
function extractUsefulErrorMessageFromResponse(req, res) {
  return Fetch.$$Response.text(res.clone()).then(function (text) {
    var json;
    try {
      json = Caml_option.some(JSON.parse(text));
    } catch (e) {
      json = undefined;
    }
    var message = truncateTo100Characters(text);
    var message$1 = message.trim() === "" ? undefinedErrorMessage : message;
    var obj = Belt_Option.flatMap(json, Js_json.decodeObject);
    if (obj === undefined) {
      return Promise.resolve(makeError(req, res, message$1, undefined));
    }
    var displayError = Belt_Option.flatMap(Js_dict.get(Caml_option.valFromOption(obj), "error"), Js_json.decodeString);
    if (displayError !== undefined) {
      return Promise.resolve(makeError(req, res, displayError, undefined));
    } else {
      return Promise.resolve(makeError(req, res, message$1, undefined));
    }
  });
}
function handleServerResponse(req, res) {
  var statusCode = res.status;
  if (statusCode >= 200 && statusCode < 300) {
    return Promise.resolve({
      TAG: /* Ok */0,
      _0: res
    });
  } else {
    return extractUsefulErrorMessageFromResponse(req, res).then(function (error) {
      return {
        TAG: /* Error */1,
        _0: error
      };
    });
  }
}
async function reportToRollbar(error) {
  var isCriticalError$1 = isCriticalError(error.response.status, error.request.url);
  var request = await toObject(error.request);
  var response = await toObject$1(error.response);
  if (isCriticalError$1) {
    return RollbarInstance$Showside.critical2(Rollbar.$$Error.fromRescript(error.exn), {
      request: request,
      response: response
    });
  } else {
    return RollbarInstance$Showside.error2(error.exn, {
      request: request,
      response: response
    });
  }
}
function reportAllServerErrors(res, req) {
  return res.then(function (param) {
    return handleServerResponse(req, param);
  }).then(function (result) {
    if (result.TAG === /* Ok */0) {
      return Promise.resolve(result._0);
    }
    var error = result._0;
    return reportToRollbar(error).then(function (param) {
      return Promise.reject(error.exn);
    });
  });
}
function ignoreNotFoundError(res, req) {
  return res.then(function (param) {
    return handleServerResponse(req, param);
  }).then(function (result) {
    if (result.TAG === /* Ok */0) {
      return Promise.resolve(result._0);
    }
    var error = result._0;
    if (error.response.status === 404) {
      return Promise.reject({
        RE_EXN_ID: ServerError,
        _1: "Not found"
      });
    } else {
      return reportToRollbar(error).then(function (param) {
        return Promise.reject(error.exn);
      });
    }
  });
}
var Server = {
  makeError: makeError,
  truncateTo100Characters: truncateTo100Characters,
  extractUsefulErrorMessageFromResponse: extractUsefulErrorMessageFromResponse,
  handleServerResponse: handleServerResponse,
  reportToRollbar: reportToRollbar,
  reportAllServerErrors: reportAllServerErrors,
  ignoreNotFoundError: ignoreNotFoundError
};
function ignoreDecode(json) {
  return Promise.resolve({
    TAG: /* Ok */0,
    _0: json
  });
}
function raiseExnIfFound(json, decoder) {
  var decodedObject = Js_json.decodeObject(json);
  var error = Belt_Option.flatMap(Belt_Option.flatMap(decodedObject, function (dict) {
    return Js_dict.get(dict, "error");
  }), Js_json.decodeString);
  var numberOfFields = Belt_Option.getWithDefault(Belt_Option.map(decodedObject, function (object) {
    return Object.keys(object).length;
  }), 0);
  if (error !== undefined && error.trim() !== "" && numberOfFields === 1) {
    return Promise.resolve({
      TAG: /* Error */1,
      _0: error
    });
  } else {
    return Curry._1(decoder, json);
  }
}
var SuccessfulResponseErrorBody = {
  raiseExnIfFound: raiseExnIfFound
};
function reportAllDecodingErrors(res, decoder) {
  return res.then(function (res) {
    return Fetch.$$Response.json(res).then(function (json) {
      var decoded = raiseExnIfFound(json, decoder);
      var url = res.url;
      var displayUrl = makeDisplayUrl(url);
      return PromiseUtils$Showside.$$catch(decoded, function (e) {
        var rootCause = Rollbar.$$Error.fromRescript(e);
        var name = Belt_Option.getWithDefault(rootCause.name, "Error");
        var message = Belt_Option.getWithDefault(rootCause.message, undefinedErrorMessage);
        var errorMessage = "Error raised when decoding " + displayUrl + ": " + name + " " + message + "";
        var error = {
          RE_EXN_ID: DecodeError,
          _1: errorMessage
        };
        if (message.includes("Failed to fetch dynamically imported module:") || message.includes("Cannot find module") || message.includes("Could not resolve bundle with id")) {
          return Promise.reject(error);
        } else {
          return toObject$1(res).then(function (response) {
            var isCriticalError$1 = isCriticalError(res.status, res.url);
            if (isCriticalError$1) {
              return RollbarInstance$Showside.critical2(Rollbar.$$Error.fromRescript(error), {
                apiUrl: url,
                response: response
              }).then(function (param) {
                return Promise.reject(error);
              });
            } else {
              return RollbarInstance$Showside.error2(error, {
                apiUrl: url,
                response: response
              }).then(function (param) {
                return Promise.reject(error);
              });
            }
          });
        }
      }).then(function (decoded) {
        if (decoded.TAG === /* Ok */0) {
          return Promise.resolve(decoded._0);
        }
        var errorMessage = "Failed to decode response of API " + displayUrl + ": " + decoded._0 + "";
        var error = {
          RE_EXN_ID: DecodeError,
          _1: errorMessage
        };
        return toObject$1(res).then(function (response) {
          var isCriticalError$1 = isCriticalError(res.status, res.url);
          if (isCriticalError$1) {
            return RollbarInstance$Showside.critical2(Rollbar.$$Error.fromRescript(error), {
              apiUrl: url,
              response: response
            }).then(function (param) {
              return Promise.reject(error);
            });
          } else {
            return RollbarInstance$Showside.error2(error, {
              apiUrl: url,
              response: response
            }).then(function (param) {
              return Promise.reject(error);
            });
          }
        });
      });
    });
  });
}
var Decoding = {
  ignoreDecode: ignoreDecode,
  SuccessfulResponseErrorBody: SuccessfulResponseErrorBody,
  reportAllDecodingErrors: reportAllDecodingErrors
};
function reportExn(e, req) {
  var url = req.url;
  var displayUrl = makeDisplayUrl(url);
  var err = Rollbar.$$Error.fromRescript(e);
  var name = Belt_Option.getWithDefault(err.name, "Error");
  var message = Belt_Option.getWithDefault(err.message, undefinedErrorMessage);
  var errorMessage = "Error raised when fetching " + displayUrl + ": " + name + " " + message + "";
  var error = Rollbar.$$Error.fromRescriptWithNewMessage(e, errorMessage);
  var isNetworkError = IsNetworkError(e);
  if (isNetworkError) {
    return Promise.reject({
      RE_EXN_ID: NetworkError,
      _1: Belt_Option.getWithDefault(err.message, "Network error")
    });
  } else {
    return toObject(req).then(function (request) {
      var isCriticalError$1 = isCriticalError(undefined, req.url);
      if (isCriticalError$1) {
        return RollbarInstance$Showside.critical2(error, {
          apiUrl: url,
          request: request
        }).then(function (param) {
          return Promise.reject(e);
        });
      } else {
        return RollbarInstance$Showside.jsError2(error, {
          apiUrl: url,
          request: request
        }).then(function (param) {
          return Promise.reject(e);
        });
      }
    });
  }
}
function $$fetch$1(method_Opt, body, headers, reportDecodingError, reportServerError, decoder, url) {
  var method_ = method_Opt !== undefined ? method_Opt : /* Get */0;
  var reportServerError$1 = Belt_Option.getWithDefault(reportServerError, reportAllServerErrors);
  var reportDecodingError$1 = Belt_Option.getWithDefault(reportDecodingError, reportAllDecodingErrors);
  var requestInit = Fetch.RequestInit.make(method_, headers, body, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined);
  var request = new Request(url, requestInit);
  try {
    return Curry._2(reportDecodingError$1, Curry._2(reportServerError$1, PromiseUtils$Showside.$$catch(fetchWithInit(url, requestInit), function (exn) {
      return reportExn(exn, request);
    }), request), decoder);
  } catch (raw_e) {
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    return reportExn(JsUtils$Showside.Exn.unwrapJsError(e), request);
  }
}
export { ServerError, DecodeError, NetworkError, fetchWithInit, $$Headers, Method, $$Request, $$Response, IsNetworkError$1 as IsNetworkError, undefinedErrorMessage, isCriticalError, makeDisplayUrl, Server, Decoding, reportExn, $$fetch$1 as $$fetch };
/* Rollbar Not a pure module */