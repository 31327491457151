// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Env$Showside from "./utils/Env.bs.js";
import * as Shopify$Showside from "./utils/Shopify.bs.js";
function waitForOptimize(retryOpt, param) {
  var retry = retryOpt !== undefined ? retryOpt : 0;
  var getExperiment = Belt_Option.flatMap(window.maker_optimize, function (o) {
    return o.get;
  });
  return new Promise(function (resolve, reject) {
    var match = window.maker_optimize;
    if (getExperiment !== undefined) {
      return resolve(getExperiment);
    } else if (match !== undefined && retry < 12) {
      setTimeout(function (param) {
        waitForOptimize(retry + 1 | 0, undefined).then(function (response) {
          resolve(response);
        });
      }, 42);
      return;
    } else {
      return resolve(undefined);
    }
  });
}
var looksLikeAFunction = function (value) {
  return typeof value === 'function' || typeof value === 'string' && value.includes('function');
};
async function getConfigId(useConfigIdFromEmbedOpt, useConfigIdFromExperimentOpt, useConfigIdFromShopifyOpt, attributes, embedType) {
  var useConfigIdFromEmbed = useConfigIdFromEmbedOpt !== undefined ? useConfigIdFromEmbedOpt : true;
  var useConfigIdFromExperiment = useConfigIdFromExperimentOpt !== undefined ? useConfigIdFromExperimentOpt : true;
  var useConfigIdFromShopify = useConfigIdFromShopifyOpt !== undefined ? useConfigIdFromShopifyOpt : true;
  var configIdFromEmbed = Belt_Option.keep(attributes.config, function (param) {
    return useConfigIdFromEmbed;
  });
  var getExperiment = useConfigIdFromExperiment ? await waitForOptimize(undefined, undefined) : undefined;
  var configIdFromExperiment = getExperiment !== undefined ? await Curry._4(getExperiment, undefined, embedType, undefined, undefined) : undefined;
  var configIdFromExperiment$1 = looksLikeAFunction(configIdFromExperiment) ? undefined : configIdFromExperiment;
  var configIdFromShopify;
  if (useConfigIdFromShopify && !(configIdFromEmbed !== undefined || configIdFromExperiment$1 !== undefined)) {
    var config = await Shopify$Showside.Config.load(Env$Showside.apiHostnameProduction);
    configIdFromShopify = config.id;
  } else {
    configIdFromShopify = undefined;
  }
  if (configIdFromExperiment$1 !== undefined) {
    if (configIdFromExperiment$1 === "empty") {
      return;
    } else {
      return configIdFromExperiment$1;
    }
  } else if (configIdFromEmbed !== undefined) {
    return configIdFromEmbed;
  } else if (configIdFromShopify !== undefined) {
    return configIdFromShopify;
  } else {
    return;
  }
}
export { waitForOptimize, looksLikeAFunction, getConfigId };
/* Env-Showside Not a pure module */