// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Int from "../../../../node_modules/rescript/lib/es6/belt_Int.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Pervasives from "../../../../node_modules/rescript/lib/es6/pervasives.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Belt_MapString from "../../../../node_modules/rescript/lib/es6/belt_MapString.js";
import * as Js_null_undefined from "../../../../node_modules/rescript/lib/es6/js_null_undefined.js";
import * as Namespace$Showside from "./Namespace.bs.js";
import * as Webapi__Dom__Element from "../../../../node_modules/rescript-webapi/lib/es6_global/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import * as MutationObserver$Showside from "../utils/MutationObserver.bs.js";
function getIdByEmbedType(t) {
  switch (t) {
    case /* Bottombar */0:
      return "maker-smartnav";
    case /* Inline */1:
      return "maker-smartnav-inline";
    case /* Launcher */2:
      return "maker-smartnav-launcher";
    case /* Section */3:
      return "maker-smartnav-section";
    case /* Menu */4:
      return "maker-smartnav-menu";
  }
}
function getEmbedTypeFromId(s) {
  switch (s) {
    case "maker-smartnav":
      return /* Bottombar */0;
    case "maker-smartnav-inline":
      return /* Inline */1;
    case "maker-smartnav-launcher":
      return /* Launcher */2;
    case "maker-smartnav-menu":
      return /* Menu */4;
    case "maker-smartnav-section":
      return /* Section */3;
    default:
      return;
  }
}
function noneIfEmpty(o) {
  return Belt_Option.keep(o, function (id) {
    return id !== "";
  });
}
function fromDict(dict) {
  var match = noneIfEmpty(Js_dict.get(dict, "data-type"));
  var sectionType;
  if (match !== undefined) {
    switch (match) {
      case "node":
        sectionType = "node";
        break;
      case "related-media":
        sectionType = "related-media";
        break;
      case "root":
        sectionType = "root";
        break;
      default:
        sectionType = undefined;
    }
  } else {
    sectionType = undefined;
  }
  return {
    config: noneIfEmpty(Js_dict.get(dict, "data-config")),
    namespace: noneIfEmpty(Js_dict.get(dict, "data-namespace")),
    account: noneIfEmpty(Js_dict.get(dict, "data-account")),
    currency: noneIfEmpty(Js_dict.get(dict, "data-currency")),
    sectionType: sectionType,
    sectionNode: noneIfEmpty(Js_dict.get(dict, "data-node")),
    sectionCardShape: Belt_Option.map(noneIfEmpty(Js_dict.get(dict, "data-shape")), function (v) {
      switch (v) {
        case "circle":
          return "circle";
        case "portrait":
          return "portrait";
        default:
          return "square";
      }
    }),
    sectionCardSize: Belt_Option.map(noneIfEmpty(Js_dict.get(dict, "data-size")), function (v) {
      switch (v) {
        case "large":
          return "large";
        case "small":
          return "small";
        default:
          return "medium";
      }
    }),
    relatedProduct: noneIfEmpty(Js_dict.get(dict, "data-product")),
    relatedCategory: noneIfEmpty(Js_dict.get(dict, "data-category")),
    navMenuTopbarTarget: noneIfEmpty(Js_dict.get(dict, "data-topbar-target")),
    navMenuTopbarTargetStyle: noneIfEmpty(Js_dict.get(dict, "data-topbar-target-style")),
    navMenuTopbarZIndex: Belt_Option.flatMap(noneIfEmpty(Js_dict.get(dict, "data-topbar-z-index")), Belt_Int.fromString),
    navMenuTopbarLayout: Belt_Option.map(noneIfEmpty(Js_dict.get(dict, "data-topbar-layout")), function (layout) {
      if (layout === "three-column") {
        return "three-column";
      } else {
        return "two-column";
      }
    }),
    navMenuTopbarEnabled: Belt_Option.map(noneIfEmpty(Js_dict.get(dict, "data-topbar-enabled")), Pervasives.bool_of_string),
    navMenuHamburgerTarget: noneIfEmpty(Js_dict.get(dict, "data-hamburger-target")),
    navMenuHamburgerTriggerButtonTarget: Js_dict.get(dict, "data-hamburger-trigger-button-target"),
    navMenuHamburgerTargetStyle: noneIfEmpty(Js_dict.get(dict, "data-hamburger-target-style")),
    navMenuHamburgerZIndex: Belt_Option.flatMap(noneIfEmpty(Js_dict.get(dict, "data-hamburger-z-index")), Belt_Int.fromString),
    navMenuBreakpointMaxWidth: Belt_Option.flatMap(noneIfEmpty(Js_dict.get(dict, "data-breakpoint-max-width")), Belt_Int.fromString),
    navMenuSiteHeaderTarget: noneIfEmpty(Js_dict.get(dict, "data-site-header-target"))
  };
}
function autoAssignNamespaces(param) {
  var groupElementsByTypeAndConfigId = function (elements) {
    return Belt_Array.map(Belt_MapString.toArray(Belt_Array.reduce(elements, undefined, function (acc, el) {
      var match = getEmbedTypeFromId(el.id);
      var typeId = match !== undefined ? match !== 1 ? match >= 4 ? "2" : "1" : "0" : "1";
      var configId = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(el.getAttribute("data-config")), "0");
      var uniqueId = typeId + configId;
      return Belt_MapString.set(acc, uniqueId, Belt_Array.concat(Belt_Option.getWithDefault(Belt_MapString.get(acc, uniqueId), []), [el]));
    })), function (param) {
      return param[1];
    });
  };
  var __x = Belt_Array.map(Belt_Array.map([/* Inline */1, /* Bottombar */0, /* Launcher */2, /* Section */3, /* Menu */4], getIdByEmbedType), function (id) {
    return "#" + id;
  }).join(",");
  Belt_Array.forEachWithIndex(groupElementsByTypeAndConfigId(Belt_Array.keepMap(Array.prototype.slice.call(document.querySelectorAll(__x)), Webapi__Dom__Element.ofNode)), function (index, e) {
    Belt_Array.forEach(e, function (el) {
      var existingNamespace = el.getAttribute("data-namespace");
      var autoNamespace = el.getAttribute("data-auto-namespace");
      if (!(existingNamespace == null) && autoNamespace == null) {
        return;
      }
      var nextNamespace = el.id === "maker-smartnav-menu" ? "menu" : index === 0 ? undefined : String(index);
      el.setAttribute("data-auto-namespace", "true");
      Belt_Option.forEach(nextNamespace, function (next) {
        el.setAttribute("data-namespace", next);
      });
    });
  });
}
function fromElement(el) {
  return fromDict(Js_dict.fromArray(Belt_Array.map(Array.prototype.slice.call(el.attributes), function (attr) {
    var key = attr.localName;
    var value = attr.value;
    return [key, value];
  })));
}
function useAttributesObserver(element, fallbackAttributes) {
  var match = React.useState(function () {
    return fallbackAttributes;
  });
  var setAttributes = match[1];
  var elRef = React.useRef(Js_null_undefined.fromOption(element));
  MutationObserver$Showside.use({
    attributes: true,
    characterData: false,
    childList: false,
    subtree: false
  }, elRef, function (param) {
    Belt_Option.forEach(element, function (element) {
      Curry._1(setAttributes, function (param) {
        return fromElement(element);
      });
    });
  });
  return match[0];
}
var Attributes = {
  fromDict: fromDict,
  autoAssignNamespaces: autoAssignNamespaces,
  fromElement: fromElement,
  useAttributesObserver: useAttributesObserver
};
function getNamespace(attributes) {
  return Belt_Option.map(Belt_Option.flatMap(attributes, function (attr) {
    return attr.namespace;
  }), Namespace$Showside.fromString);
}
export { getIdByEmbedType, getEmbedTypeFromId, noneIfEmpty, Attributes, getNamespace };
/* react Not a pure module */