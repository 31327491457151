// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Rollbar from "../../../../node_modules/rescript-rollbar/lib/es6_global/src/Rollbar.bs.js";
import * as Env$Showside from "./Env.bs.js";
import * as Caml_exceptions from "../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as Caml_js_exceptions from "../../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
var importRollbarClient = function () {
  return import('rollbar/dist/rollbar.noconflict.umd').then(rollbar => {
    return options => {
      return new rollbar(options);
    };
  });
};
function getEnvironment(param) {
  var host = window.location.host;
  var isIgnoredHost = host.endsWith(".vercel.app") || host.endsWith(".pages.dev") || host.endsWith("showside.maker.co") || host.endsWith("showside-staging.maker.co") || host.endsWith("nav.maker.co") || host.endsWith(".myshopify.com") || host.endsWith(".shopifypreview.com") || host.endsWith(".glitch.me") || host.endsWith("localhost:3000") || host.endsWith("localhost:3002");
  if (isIgnoredHost && Env$Showside.nodeEnv === "production") {
    return "production-ignored";
  } else {
    return Env$Showside.nodeEnv;
  }
}
async function getInstance(param) {
  var makeClient = await Curry._1(importRollbarClient, undefined);
  return makeClient({
    accessToken: Env$Showside.rollbarAccessToken,
    captureUncaught: false,
    captureUnhandledRejections: false,
    environment: getEnvironment(undefined),
    checkIgnore: undefined,
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: Env$Showside.gitCommitSha,
          guess_uncaught_frames: true
        }
      }
    },
    codeVersion: Env$Showside.gitCommitSha
  });
}
var LogicalError = /* @__PURE__ */Caml_exceptions.create("RollbarInstance-Showside.LogicalError");
var LogicalErrorUnreported = /* @__PURE__ */Caml_exceptions.create("RollbarInstance-Showside.LogicalErrorUnreported");
function makeError(reportOpt, message) {
  var report = reportOpt !== undefined ? reportOpt : true;
  try {
    throw report ? {
      RE_EXN_ID: LogicalError,
      _1: message
    } : {
      RE_EXN_ID: LogicalErrorUnreported,
      _1: message
    };
  } catch (raw_err) {
    return Caml_js_exceptions.internalToOCamlException(raw_err);
  }
}
var addHostToErrorMessage = error => {
  if (typeof window !== "undefined" && window.location.host !== "") {
    if (typeof error === "string") {
      return "[" + window.location.host + "] " + error;
    } else if (typeof error === "object" && error instanceof Error) {
      error.message = "[" + window.location.host + "] " + error.message;
      return error;
    } else {
      return error;
    }
  } else {
    return error;
  }
};
function jsError1(error) {
  return getInstance(undefined).then(function (__x) {
    return Rollbar.error1(__x, addHostToErrorMessage(error));
  });
}
function jsError2(error, extraInfo) {
  return getInstance(undefined).then(function (__x) {
    return Rollbar.error2(__x, addHostToErrorMessage(error), addHostToErrorMessage(extraInfo));
  });
}
function jsError3(error, extraInfo1, extraInfo2) {
  return getInstance(undefined).then(function (__x) {
    return Rollbar.error3(__x, addHostToErrorMessage(error), addHostToErrorMessage(extraInfo1), addHostToErrorMessage(extraInfo2));
  });
}
function error1(error) {
  return jsError1(Rollbar.$$Error.fromRescript(error));
}
function error2(error, extraInfo) {
  return jsError2(Rollbar.$$Error.fromRescript(error), extraInfo);
}
function error3(error, extraInfo1, extraInfo2) {
  return jsError3(Rollbar.$$Error.fromRescript(error), extraInfo1, extraInfo2);
}
function info1(msg) {
  return getInstance(undefined).then(function (__x) {
    return Rollbar.info1(__x, msg);
  });
}
function info2(msg, extraInfo) {
  return getInstance(undefined).then(function (__x) {
    return Rollbar.info2(__x, msg, extraInfo);
  });
}
function warning1(msg) {
  return getInstance(undefined).then(function (__x) {
    return Rollbar.warning1(__x, msg);
  });
}
function warning2(msg, extraInfo) {
  return getInstance(undefined).then(function (__x) {
    return Rollbar.warning2(__x, msg, extraInfo);
  });
}
function critical1(error) {
  return getInstance(undefined).then(function (__x) {
    return Rollbar.critical1(__x, addHostToErrorMessage(error));
  });
}
function critical2(error, extraInfo) {
  return getInstance(undefined).then(function (__x) {
    return Rollbar.critical2(__x, addHostToErrorMessage(error), addHostToErrorMessage(extraInfo));
  });
}
function critical3(error, extraInfo1, extraInfo2) {
  return getInstance(undefined).then(function (__x) {
    return Rollbar.critical3(__x, addHostToErrorMessage(error), addHostToErrorMessage(extraInfo1), addHostToErrorMessage(extraInfo2));
  });
}
export { importRollbarClient, getEnvironment, getInstance, LogicalError, LogicalErrorUnreported, makeError, addHostToErrorMessage, jsError1, jsError2, jsError3, error1, error2, error3, info1, info2, warning1, warning2, critical1, critical2, critical3 };
/* Rollbar Not a pure module */