// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as CSSVersion$Showside from "../CSSVersion.bs.js";
function getCurrentScriptOrigin(param) {
  return localStorage && localStorage.getItem('smartnav-script-origin') || 'https://showside.maker.co';
}
var match = CSSVersion$Showside.getVersion(undefined);
var cssFile = match === "v3" ? "smartnav-v3.css" : "smartnav-v2.css";
function alreadyLoaded(param) {
  return Belt_Option.isSome(Caml_option.nullable_to_opt(document.querySelector("link[rel=\"stylesheet\"][href*=\"" + cssFile + "\"]")));
}
function insertCSS(onLoadedOpt, param) {
  var onLoaded = onLoadedOpt !== undefined ? onLoadedOpt : function (param) {};
  if (alreadyLoaded(undefined)) {
    return Curry._1(onLoaded, undefined);
  }
  var link = document.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("as", "style");
  link.setAttribute("href", getCurrentScriptOrigin(undefined) + ("/" + cssFile + ""));
  link.setAttribute("id", "maker-smartnav-style");
  link.addEventListener("load", function (param) {
    Curry._1(onLoaded, undefined);
  });
  Belt_Option.forEach(document.body, function (param) {
    param.appendChild(link);
  });
}
export { cssFile, alreadyLoaded, insertCSS };
/* match Not a pure module */