// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
function getKey(key) {
  if (typeof key === "object") {
    var variant = key.NAME;
    if (variant === "optimizeVariant") {
      return "mkr_variant__" + key.VAL + "";
    }
    if (variant === "clientId") {
      return "mkr_client_id__" + key.VAL + "";
    }
  } else if (key === "legacyClientId") {
    return "mkr_client_id";
  }
  var tmp;
  if (typeof key === "object") {
    var variant$1 = key.NAME;
    tmp = variant$1 === "optimizeVariant" ? "optimizeVariant" : variant$1 === "scrollRestoration" ? "scroll-" + key.VAL : variant$1 === "history" ? "history" + Belt_Option.mapWithDefault(key.VAL, "", function (id) {
      return "-" + id;
    }) : variant$1 === "clientId" ? "clientId" : variant$1 === "favorites" ? "favorites" + Belt_Option.mapWithDefault(key.VAL, "", function (id) {
      return "-" + id;
    }) : variant$1 === "selectedMarket" ? "selectedMarket-" + key.VAL : "cart-" + key.VAL;
  } else {
    tmp = key === "debuggerAllowed" ? "debuggerAllowed" : key === "navMenuConfig" ? "navMenuConfig" : key === "legacyClientId" ? "clientId" : key === "gridColumns" ? "gridColumns" : "intro";
  }
  return "smartnav__v" + String(2) + "__" + tmp;
}
function get(_storage, key) {
  while (true) {
    var storage = _storage;
    try {
      return Belt_Option.flatMap(Caml_option.null_to_opt(Belt_Option.getWithDefault(storage, localStorage).getItem(getKey(key))), function (value) {
        if (value === "") {
          return;
        } else {
          return value;
        }
      });
    } catch (exn) {
      if (storage !== undefined) {
        return;
      }
      _storage = Caml_option.some(sessionStorage);
      continue;
    }
  }
  ;
}
function set(_storage, key, value) {
  while (true) {
    var storage = _storage;
    try {
      Belt_Option.getWithDefault(storage, localStorage).setItem(getKey(key), value);
      return;
    } catch (exn) {
      if (storage !== undefined) {
        return;
      }
      _storage = Caml_option.some(sessionStorage);
      continue;
    }
  }
  ;
}
function $$delete(_storage, key) {
  while (true) {
    var storage = _storage;
    try {
      Belt_Option.getWithDefault(storage, localStorage).removeItem(getKey(key));
      return;
    } catch (exn) {
      if (storage !== undefined) {
        return;
      }
      _storage = Caml_option.some(sessionStorage);
      continue;
    }
  }
  ;
}
var version = 2;
export { version, getKey, get, set, $$delete };
/* No side effect */